/*!
 *   _                 _           _ ____  
 *  | |__   ___  _ __ | | __ _    | / ___| 
 *  | '_ \ / _ \| '_ \| |/ _` |_  | \___ \ 
 *  | | | | (_) | |_) | | (_| | |_| |___) |
 *  |_| |_|\___/| .__/|_|\__,_|\___/|____/ 
 *              |_|                        
 * 
 * This file is part of hoplaJS.
 * See: <https://github.com/golflima/hoplaJS>.
 *
 * Copyright (C) 2017 Jérémy Walther <jeremy.walther@golflima.net>.
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 * Otherwise, see: <https://www.gnu.org/licenses/agpl-3.0>.
 */
 
body {
    padding-top: 70px;
    padding-bottom: 30px;
}

.fit-col-sm {
    padding-left: 15px;
    padding-right: 15px;
}

.font-code { 
    font-family: Consolas, Lucida Console, monospace; 
}

label {
    margin-bottom: 0;
}

.spinner-1s-cw {
    animation: spinner-clockwise 1s infinite linear;
}

@keyframes spinner-clockwise {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}